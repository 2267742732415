import React from 'react';
import Image from 'reusecore/src/elements/Image';
import { Link } from 'gatsby';
import DocumentationSection from '../../../../../containers/SaasModern/Documentation';
import todoist_1 from '../../../../../../../common/src/assets/image/todoist_1.png';
import todoist_2 from '../../../../../../../common/src/assets/image/todoist_2.png';
import todoist_3 from '../../../../../../../common/src/assets/image/todoist_3.png';
import todoist_4 from '../../../../../../../common/src/assets/image/todoist_4.png';
import todoist_5 from '../../../../../../../common/src/assets/image/todoist_5.png';

const Documentation = () => {
  const content = (
    <div>
      <p>
        Need to print your Todoist tasks? PrintableCal includes <Link to="/Documentation/Create-Calendar/Choose-a-Template">
        over 90 printable calendar templates and layouts</Link> which can include tasks that are defined in Todoist. You can print multiple task projects at the same time, even if the projects are associated with different Todoist accounts.
      </p>
      <p>
        Here's how to connect PrintableCal to your Todoist task projects:
      </p>
      <ol className="numbered">
        <li>
          Click the <strong>Add Calendar</strong> button on the Calendar Data
          Sources step and select the <strong>Online -&gt; Todoist </strong>
          option. The <strong>Add Calendar Source - Todoist</strong> window will
          appear.
          <br />
          <Image alt="" src={todoist_1} style={{ width: 690 }} />
        </li>
        <li>
          PrintableCal can import from any number of Todoist accounts. To import
          from a previously added account, select the{' '}
          <strong>Use an existing account</strong> option and pick the desired
          account from the drop-down list. Otherwise, select the{' '}
          <strong>Add an account</strong> option and continue to the next step.
        </li>
        <li>
          Enter an account name in the <strong>Account name</strong> field.
        </li>
        <li>
          Open{' '}
          <a
            href="https://www.todoist.com"
            target="_blank"
            rel="noopener noreferrer"
          >
            https://www.todoist.com
          </a>{' '}
          in your web browser. Log into your account if needed.
        </li>
        <li>
          Click your profile circle in the upper-right corner and select <strong>Settings</strong> from the drop-down menu.
          <br />
          <Image alt="" src={todoist_2} style={{ width: 364 }} />
        </li>
        <li>
          On the Settings page, click <strong>Integrations</strong> and then click <strong>Developer</strong>.
          <Image alt="" src={todoist_3} style={{ width: 690 }} />
        </li>
        <li>
          Click the <strong>Copy to clipboard</strong> button to copy the API token.
          <br />
          <Image alt="" src={todoist_4} style={{ width: 541 }} />
        </li>
        <li>
          Paste (Ctrl+V) into the <strong>API token</strong> field on the{' '}
          <strong>Add Calendar Data Source - Todoist</strong> window.
        </li>
        <li>
          Click the Next button. PrintableCal will connect to your account and
          provide a list of your projects. Use the checkboxes to select the
          projects you'd like to make available to PrintableCal as calendar data
          sources.
          <br />
          <Image alt="" src={todoist_5} style={{ width: 690 }} />
        </li>
        <li>
          Click the <strong>OK </strong>button to add your Todoist projects to
          PrintableCal's list of available calendar sources.
        </li>
      </ol>
    </div>
  );

  return (
    <DocumentationSection
      url="https://www.printablecal.com/Documentation/Create-Calendar/Calendar-Data-Sources/Todoist"
      commentsId="commentsplus_post_1197_1642"
      title="Print Todoist Tasks, Subtasks, and Multiple Task Projects"
      description="PrintableCal can create printable calendars containing tasks that are defined in Todoist."
      keywords="todoist, import todoist, print todoist, print tasks, calendar template, printable calendar, printable tasks, import tasks, Excel calendar, Word calendar"
      content={content}
    />
  );
};

export default Documentation;
